import * as React from "react";
import {Create, SimpleForm, TextInput} from "react-admin";

import {validateId} from "../admin.validation";

export const AdminCreate: React.FC = () => (
    <Create>
        <SimpleForm>
            <TextInput source="id" type="email" validate={validateId} />
        </SimpleForm>
    </Create>
);
