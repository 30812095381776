import React from "react";
import {SaveButton, Toolbar, Identifier, Create} from "react-admin";

import {UserRecommendationForm} from "../forms/user-recommendation.form";

export const UserRecommendationQuickCreate: React.FC<{userId: Identifier}> = ({userId}) => (
    <Create resource="userRecommendations">
        <UserRecommendationForm
            toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }
            userId={userId}
        />
    </Create>
);
