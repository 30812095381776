/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import lodashMemoize from "lodash.memoize";
import {ValidationErrorMessage} from "react-admin";

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
export const memoize = <T extends (...args: any) => any>(fn: T): T =>
    lodashMemoize(fn, (...args: any) => JSON.stringify(args));

export interface MessageFuncParams {
    args: any;
    value: any;
    values: any;
}
export type MessageFunc = (params: MessageFuncParams) => ValidationErrorMessage;

export function getMessage(
    message: string | MessageFunc,
    messageArgs: any,
    value: any,
    values: any
): ValidationErrorMessage {
    if (typeof message === "function") return message({args: messageArgs, value, values});
    return messageArgs ? {message, args: messageArgs} : message;
}
