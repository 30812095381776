import {maxLength, minValue, required, Validator} from "react-admin";

import {integer} from "../../validators";

export const validateName: Array<Validator> = [required(), maxLength(128)];
export const validateDescription: Array<Validator> = [required(), maxLength(1024)];
export const validateVideoId: Array<Validator> = [maxLength(11)];
export const validateExecutionFrequency: Array<Validator> = [maxLength(256)];
export const validateStage: Array<Validator> = [required(), minValue(0), integer()];
export const validateExerciseSetId: Array<Validator> = [required()];
