import {IDisease} from "$types/disease.interface";
import {IExerciseSet} from "$types/exercise-set.interface";
import {IUser} from "$types/user.interface";

import * as React from "react";
import {
    Datagrid,
    TextField,
    NumberField,
    ReferenceInput,
    ReferenceField,
    FunctionField,
    DateField,
    AutocompleteInput,
    ListBase,
    FilterForm,
    Pagination,
    useListContext,
    useReference,
    useGetManyAggregate
} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";
import {UseFormReturn} from "react-hook-form/dist/types";

import Card from "@mui/material/Card";

import {ExerciseSetRecordRepresentation} from "../../exercise-set";
import {UserFilter} from "../../user/user.filter";

const FormFilters: React.FC<{form: UseFormReturn}> = ({form}) => {
    const {filterValues} = useListContext();
    const userId = form.watch("userId");
    const {referenceRecord: user, isLoading: isLoadingUser} = useReference<IUser>({
        reference: "users",
        id: userId
    });
    const {data: diseases = [], isLoading: isLoadingDiseases} = useGetManyAggregate<IDisease>(
        "diseases",
        {
            ids: user?.diseases ?? []
        }
    );

    return (
        <div style={{display: "flex"}}>
            <FormProvider {...form}>
                <UserFilter form={form} />
            </FormProvider>
            <FilterForm
                style={{flex: "unset"}}
                filters={[
                    <AutocompleteInput
                        key="diseaseId"
                        optionText="name"
                        source="diseaseId"
                        choices={diseases}
                        isLoading={isLoadingDiseases}
                        disabled={isLoadingUser || !userId}
                        alwaysOn
                    />,
                    <ReferenceInput
                        key="exerciseSetId"
                        source="exerciseSetId"
                        reference="exerciseSets"
                        label="Exercise Set"
                        // disable filter if no disease selected
                        filter={
                            filterValues?.diseaseId
                                ? {diseaseId: filterValues?.diseaseId}
                                : undefined
                        }
                        sort={{field: "stage", order: "ASC"}}
                        alwaysOn
                    >
                        <AutocompleteInput
                            optionText={<ExerciseSetRecordRepresentation />}
                            inputText={(record: IExerciseSet): string => record.name}
                            matchSuggestion={(filter: string, choice: IExerciseSet): boolean =>
                                // I Tried to add searching by disease name as well,
                                // but react admin firebase filterToQuery prop requirement causes filtering the data with the name of disease which results in empty list
                                // diseases.some(({name, id}) => choice.diseaseId === id && name.toLowerCase().includes(filter.toLowerCase())) ||
                                choice.name.toLowerCase().includes(filter.toLowerCase())
                            }
                            filterToQuery={(q): Partial<IExerciseSet> => ({name: q})}
                            disabled={!userId}
                            sx={{width: "320px"}}
                        />
                    </ReferenceInput>
                ]}
            />
        </div>
    );
};

export const ProgressList: React.FC = () => {
    const form = useForm();
    const userId = form.watch("userId") as string | void;

    /* In order to satisfy security rules we have to define userId as a permanent filter, so we need ask for it before rendering the list */
    /* That's why we render just the form filters as long as the userId is missing */
    if (!userId) return <FormFilters form={form} />;

    return (
        <ListBase key={userId} filter={{userId}} sort={{field: "date", order: "DESC"}}>
            <FormFilters form={form} />
            <Card>
                <Datagrid>
                    <ReferenceField reference="users" source="userId" link="show">
                        <FunctionField
                            render={(record: IUser | void): string =>
                                record ? `${record.firstname} ${record.lastname}` : "N/A"
                            }
                        />
                    </ReferenceField>
                    <ReferenceField reference="diseases" source="diseaseId" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField reference="exerciseSets" source="exerciseSetId" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="timeSpent" />
                    <NumberField source="exerciseQuantity" />
                    <DateField source="date" showTime />
                </Datagrid>
            </Card>
            <Pagination />
        </ListBase>
    );
};
