import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    usePermissions,
    ReferenceField,
    NumberField
} from "react-admin";

export const ExerciseSetList: React.FC = () => {
    const {permissions} = usePermissions();
    return (
        <List sort={{field: "stage", order: "ASC"}}>
            <Datagrid bulkActionButtons={permissions?.isAdmin ? void 0 : false}>
                <TextField source="name" />
                <NumberField source="stage" />
                <ReferenceField reference="diseases" source="diseaseId" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="description" />
                <ShowButton label="" />
                {permissions?.isAdmin && <EditButton label="" />}
                {permissions?.isAdmin && <DeleteButton label="" />}
            </Datagrid>
        </List>
    );
};
