import * as React from "react";
import {useLocaleState, useTranslate} from "react-admin";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {SelectInputProps} from "@mui/material/Select/SelectInput";

import {ELocales} from "../../i18n/locales.enum";

export const LocaleSelector: React.FC = () => {
    const [locale, setLocale] = useLocaleState();
    const translate = useTranslate();

    const handleChange: SelectInputProps<ELocales>["onChange"] = ({target: {value}}) => {
        setLocale(value);
    };

    return (
        <>
            <InputLabel id="language-select-label" shrink={true} style={{color: "white"}}>
                {translate("appbar.language")}
            </InputLabel>
            <FormControl>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={locale as ELocales}
                    onChange={handleChange}
                    variant="standard"
                >
                    <MenuItem value={ELocales.english}>
                        {translate("appbar.languages.english")}
                    </MenuItem>
                    <MenuItem value={ELocales.polish}>
                        {" "}
                        {translate("appbar.languages.polish")}
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    );
};
