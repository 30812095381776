import React from "react";
import {Resource} from "react-admin";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import {StaffCreate} from "./views/staff.create";
import {StaffEdit} from "./views/staff.edit";
import {StaffList} from "./views/staff.list";
import {StaffShow} from "./views/staff.show";

export function staffResource(): JSX.Element {
    return (
        <Resource
            icon={AssignmentIndIcon}
            name="staff"
            show={StaffShow}
            list={StaffList}
            edit={StaffEdit}
            create={StaffCreate}
        />
    );
}
