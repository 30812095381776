import {getAuth, GoogleAuthProvider} from "firebase/auth";
import * as firebaseui from "firebaseui";
import React from "react";
import {Login, LoginForm, LoginProps} from "react-admin";
import {StyledFirebaseAuth} from "react-firebaseui";

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
    // Popup sign-in flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "#/",
    signInOptions: [GoogleAuthProvider.PROVIDER_ID]
};

const CustomLoginPage: React.FC<LoginProps> = (props) => (
    <Login {...props}>
        <div>
            <LoginForm {...props} />
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
        </div>
    </Login>
);
export default CustomLoginPage;
