import React from "react";
import {Resource} from "react-admin";

import SickIcon from "@mui/icons-material/Sick";

import {DiseaseCreate} from "./views/disease.create";
import {DiseaseEdit} from "./views/disease.edit";
import {DiseaseList} from "./views/disease.list";
import {DiseaseShow} from "./views/disease.show";

export function diseaseResource({isAdmin}: {isAdmin?: boolean}): JSX.Element {
    return (
        <Resource
            icon={SickIcon}
            name="diseases"
            show={DiseaseShow}
            list={DiseaseList}
            create={isAdmin ? DiseaseCreate : void 0}
            edit={isAdmin ? DiseaseEdit : void 0}
        />
    );
}
