import * as React from "react";
import {List, Datagrid, ShowButton, DeleteButton, EmailField} from "react-admin";

export const AdminList: React.FC = () => (
    <List>
        <Datagrid>
            <EmailField source="id" />
            <ShowButton label="" />
            <DeleteButton label="" />
        </Datagrid>
    </List>
);
