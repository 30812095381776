import React from "react";
import {Menu as RaMenu} from "react-admin";

export const Menu: React.FC = () => {
    return (
        <RaMenu>
            <RaMenu.DashboardItem />
            <RaMenu.ResourceItem name="users" />
            <RaMenu.ResourceItem name="staff" />
            <RaMenu.ResourceItem name="admins" />
            <RaMenu.ResourceItem name="progress" />
            <RaMenu.ResourceItem name="progressStats" />
            <RaMenu.ResourceItem name="visits" />
            {/*There is no visit comment list view*/}
            {/*<RaMenu.ResourceItem name="visitComments" />*/}
            <RaMenu.ResourceItem name="exerciseTypes" />
            <RaMenu.ResourceItem name="exercises" />
            <RaMenu.ResourceItem name="exerciseSets" />
            <RaMenu.ResourceItem name="diseases" />
        </RaMenu>
    );
};
