import * as React from "react";
import {Edit} from "react-admin";

import {UserForm} from "../forms";

export const UserEdit: React.FC = () => (
    <Edit mutationMode="pessimistic">
        <UserForm />
    </Edit>
);
