import React from "react";
import {Admin} from "react-admin";
import {FirebaseDataProvider, FirebaseAuthProvider} from "react-admin-firebase";

import "./App.css";
import {
    adminResource,
    diseaseResource,
    exerciseSetResource,
    exerciseResource,
    progressResource,
    staffResource,
    userResource,
    visitCommentResource,
    visitResource,
    progressStatsResource
} from "./entities";
import {userRecommendationResource} from "./entities/user-recommendation";
import {initializeFirebaseService} from "./firebase-admin-init";
import {i18nProvider} from "./i18n/i18n.provider";
import {HomePage} from "./pages/HomePage";
import MyLayout from "./pages/layout";
import CustomLoginPage from "./pages/login";

const LazyAdmin = React.lazy(async () => {
    const {config, adminOptions} = await initializeFirebaseService();
    const dataProvider = FirebaseDataProvider(config, adminOptions);
    const authProvider = FirebaseAuthProvider(config, adminOptions);

    return {
        default: (): JSX.Element => (
            <Admin
                layout={MyLayout}
                dataProvider={dataProvider}
                authProvider={authProvider}
                loginPage={CustomLoginPage}
                i18nProvider={i18nProvider}
                dashboard={HomePage}
            >
                {async (permissions): Promise<Array<JSX.Element | null>> => {
                    if (!permissions) return authProvider.logout(void 0).then(() => [null]);
                    for (let i = 0; i <= 5; i++) {
                        // retry getting permissions since the first time sign up requires a function to finish
                        if (permissions.isAdmin || permissions.isStaff) break;
                        console.log(
                            "Failed getting permissions. First time setup might require permissions to be reacquired.\nRetrying getting permissions"
                        );
                        const user = (await authProvider.checkAuth(void 0)) as unknown as {
                            getIdTokenResult: (
                                forceRefresh?: boolean
                            ) => Promise<{claims: unknown}>;
                        };
                        permissions = (await user.getIdTokenResult(true)).claims ?? {};
                        await new Promise((res) => setTimeout(res, 500));
                    }

                    const resourceList = [
                        userResource(permissions),
                        userRecommendationResource(),
                        staffResource(),
                        visitResource(),
                        visitCommentResource(),
                        progressResource(),
                        progressStatsResource(),

                        exerciseResource(permissions),
                        exerciseSetResource(permissions),
                        diseaseResource(permissions)
                    ];

                    if (permissions.isAdmin) return [adminResource(), ...resourceList];
                    if (permissions.isStaff) return resourceList;

                    // safety check for signing out in case an account has been removed, or it does not actually have proper permissions
                    return authProvider.logout(void 0).then(() => [null]);
                }}
            </Admin>
        )
    };
});

const App: React.FC = () => (
    <React.Suspense fallback={<h2>Loading...</h2>}>
        <LazyAdmin />
    </React.Suspense>
);

export default App;
