import * as React from "react";
import {Edit} from "react-admin";

import {UserRecommendationForm} from "../forms/user-recommendation.form";

export const UserRecommendationEdit: React.FC = () => (
    <Edit mutationMode="pessimistic">
        <UserRecommendationForm />
    </Edit>
);
