import {IStaff} from "$types/staff.interface";
import {IVisit} from "$types/visit.interface";

import {query, where} from "firebase/firestore";
import React, {useCallback, useState} from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    EditButton,
    List,
    ShowButton,
    TextField,
    Title,
    useDataProvider,
    useGetOne,
    useLocaleState,
    usePermissions,
    useRedirect,
    useTranslate
} from "react-admin";
import {FireStoreCollectionRef} from "react-admin-firebase/dist/misc/firebase-models";
import {FireStoreQuery} from "react-admin-firebase/src/misc/firebase-models";

import FullCalendar from "@fullcalendar/react";

import {DateSpanApi, EventApi, EventClickArg, EventSourceFunc, ViewApi} from "@fullcalendar/core";
import enLocale from "@fullcalendar/core/locales/en-gb";
import plLocale from "@fullcalendar/core/locales/pl";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import {formatWorkHoursToWorkSpec, IWorkSpec} from "../../../utils/calendar";
import {parseVisitToEvent} from "../visit.validation";
import {VisitQuickCreate} from "./visit.quick-create";

export const VisitCard: React.FC<{
    timeText: string;
    event: EventApi & {extendedProps: Omit<IVisit, "id">};
}> = (eventInfo) => {
    // const visit = {id: eventInfo.event.id, ...eventInfo.event.extendedProps};
    // const {staffEmail} = visit;
    return (
        <>
            <b>{eventInfo.timeText}</b> <i>{eventInfo.event.title}</i>
        </>
    );
};

interface IDateSelect {
    start: Date;
    end: Date;
    view: ViewApi;
}

export const VisitList: React.FC = () => {
    const calendarReference = React.createRef<FullCalendar>();
    const {
        permissions: {email, isAdmin}
    } = usePermissions();
    const [showDatagrid, setShowDatagrid] = useState<boolean>(false);
    const [currentLocale] = useLocaleState();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [showCreateDialog, setShowCreateDialog] = useState<IDateSelect | null>(null);

    // not calling retry on staff queries since admin accounts do not need staff-data to render the calendar
    const {isLoading, data: staffData} = useGetOne<IStaff>(
        "staff",
        {id: email},
        {retry: isAdmin ? false : 3}
    );
    const openNewVisitDialog = useCallback(
        (e: IDateSelect) => setShowCreateDialog(e),
        [setShowCreateDialog]
    );
    const closeNewVisitDialog = useCallback(() => {
        calendarReference.current?.getApi().unselect();
        setShowCreateDialog(null);
    }, [calendarReference]);
    const showEvent = useCallback(
        (calendarEvent: EventClickArg) => redirect(`/visits/${calendarEvent.event.id}/show`),
        [redirect]
    );
    const getEvents: EventSourceFunc = useCallback(
        ({start, end}: {start: Date; end: Date}) => {
            const collectionQuery = (ref: FireStoreCollectionRef): FireStoreQuery => {
                const filters = [where("start", ">=", start), where("start", "<=", end)];
                if (!isAdmin) filters.push(where("staffEmail", "==", email));
                return query(ref, ...filters);
            };
            return dataProvider
                .getList<IVisit & {id: string}>("visits", {
                    filter: {collectionQuery},
                    pagination: {page: 1, perPage: 1000},
                    sort: {field: "start", order: "ASC"}
                })
                .then((visits) =>
                    visits.data
                        .filter(({status}) => !(status && status === "CANCELLED"))
                        .map((visit) => parseVisitToEvent(visit))
                );
        },
        [dataProvider, email, isAdmin]
    );

    if (isLoading) return null;

    if (showDatagrid)
        return (
            <List
                sort={{field: "start", order: "DESC"}}
                filter={{
                    collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery => {
                        return isAdmin ? ref : query(ref, where("staffEmail", "==", email));
                    }
                }}
            >
                <Datagrid>
                    <ShowButton label="" />
                    <EditButton label="" />
                    <TextField source="title" />
                    <DateField source="start" showTime />
                    <DateField source="end" showTime />
                    <TextField source="userId" />
                    <TextField source="staffEmail" />
                    <BooleanField source="notAvailable" />
                </Datagrid>
            </List>
        );

    const workSpec: Array<IWorkSpec> | false =
        staffData && !isAdmin ? formatWorkHoursToWorkSpec(staffData.workHours) : false;
    const calendarHourRange: {
        slotMinTime: string;
        slotMaxTime: string;
    } | null = workSpec
        ? {
              slotMinTime: workSpec
                  .map((item) => ("0" + item.startTime).slice(-5))
                  .sort()
                  .shift()!,
              slotMaxTime: workSpec
                  .map((item) => ("0" + item.endTime).slice(-5))
                  .sort()
                  .pop()!
          }
        : null;

    return (
        <Box height="100%" marginTop="20px" marginBottom="20px">
            <Title
                title={translate("ra.page.list", {
                    name: translate("resources.visits.name", {smart_count: 2})
                })}
            />
            <FullCalendar
                ref={calendarReference}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                customButtons={{
                    allTime: {
                        text: translate("form.tab.all-time"),
                        click: () => setShowDatagrid(!showDatagrid)
                    }
                }}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "allTime,dayGridMonth,timeGridWeek,timeGridDay"
                }}
                initialView="timeGridWeek"
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                selectOverlap={false}
                allDaySlot={false}
                locales={[enLocale, plLocale]}
                locale={currentLocale}
                firstDay={1}
                // only allow scheduling events for staff business hours
                selectConstraint={workSpec ? "businessHours" : undefined}
                selectAllow={(selectInfo: DateSpanApi): boolean => {
                    // only allow scheduling events for the future
                    return selectInfo.start > new Date();
                }}
                eventConstraint="businessHours"
                events={getEvents}
                weekends={true}
                select={openNewVisitDialog}
                eventContent={VisitCard}
                eventClick={showEvent}
                businessHours={workSpec}
                {...calendarHourRange}
                expandRows={true}
                height="100%"
                slotDuration={{minutes: staffData?.visitTime ?? 30}}
            />
            <Dialog
                data-testid="dialog-add-visit-comment"
                fullWidth
                open={Boolean(showCreateDialog)}
                onClose={closeNewVisitDialog}
                aria-label={translate("quick-create.visit")}
            >
                <DialogTitle>{translate("quick-create.visit")}</DialogTitle>
                <VisitQuickCreate
                    onSave={closeNewVisitDialog}
                    initialEventData={showCreateDialog!}
                />
            </Dialog>
        </Box>
    );
};
