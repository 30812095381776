import React from "react";
import {
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";

import {
    validateDescription,
    validateDiseaseId,
    validateName,
    validateStage,
    validateRevenueCatIdentifier,
    validateExerciseCount
} from "../exercise-set.validation";

export const ExerciseSetForm: React.FC = () => {
    return (
        <SimpleForm>
            <TextInput source="id" />
            <ImageInput source="icon" accept="image/*" maxSize={2097152 /*2MB size*/}>
                <ImageField source="src" title="image" />
            </ImageInput>
            <TextInput source="name" validate={validateName} fullWidth />
            <NumberInput source="stage" validate={validateStage} step={1} />
            <NumberInput
                source="exerciseCount"
                validate={validateExerciseCount}
                step={1}
                defaultValue={0}
            />
            <TextInput source="description" validate={validateDescription} fullWidth />
            <TextInput
                source="revenueCatIdentifier"
                validate={validateRevenueCatIdentifier}
                fullWidth
            />
            <ReferenceInput reference="diseases" source="diseaseId">
                <SelectInput optionText="name" fullWidth validate={validateDiseaseId} />
            </ReferenceInput>
        </SimpleForm>
    );
};
