import React from "react";
import {SaveButton, Toolbar, Identifier, Create} from "react-admin";

import {ExerciseForm} from "../forms";

export const ExerciseQuickCreate: React.FC<{exerciseSetId: Identifier}> = ({exerciseSetId}) => (
    <Create resource="exercises">
        <ExerciseForm
            toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }
            exerciseSetId={exerciseSetId}
        />
    </Create>
);
