import {Button} from "ra-ui-materialui";
import * as React from "react";
import {useState} from "react";
import {
    Show,
    TextField,
    SimpleShowLayout,
    Datagrid,
    ImageField,
    ReferenceManyField,
    NumberField,
    ShowButton,
    EditButton,
    DeleteButton,
    usePermissions,
    TopToolbar,
    useTranslate,
    useGetRecordId
} from "react-admin";

import ContentAdd from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import {ExerciseQuickCreate} from "../../exercise/views/exercise.quick-create";

const ExerciseSetShowActions: React.FC = () => {
    const exerciseSetId = useGetRecordId();
    const translate = useTranslate();
    const {permissions} = usePermissions();
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    if (!permissions?.isAdmin) return null;
    return (
        <TopToolbar>
            <Button
                onClick={(): void => setShowCreateDialog(true)}
                label={translate("quick-create.exercise")}
            >
                <ContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showCreateDialog}
                onClose={(): void => setShowCreateDialog(false)}
                aria-label={translate("quick-create.comment")}
            >
                <DialogTitle>{translate("quick-create.comment")}</DialogTitle>
                <ExerciseQuickCreate exerciseSetId={exerciseSetId} />
            </Dialog>
            {permissions?.isAdmin && <EditButton label="" />}
            {permissions?.isAdmin && <DeleteButton label="" />}
        </TopToolbar>
    );
};

export const ExerciseSetShow: React.FC = () => {
    const {permissions} = usePermissions();
    const translate = useTranslate();

    return (
        <Show actions={<ExerciseSetShowActions />}>
            <SimpleShowLayout>
                <ImageField source="icon.src" title="icon.title" />
                <TextField source="name" />
                <TextField source="description" />

                <ReferenceManyField
                    reference="exercises"
                    target="exerciseSetId"
                    label={translate("resources.exercises.name", {smart_count: 2})}
                    sort={{field: "stage", order: "ASC"}}
                >
                    <Datagrid bulkActionButtons={permissions?.isAdmin ? void 0 : false}>
                        <TextField source="name" />
                        <NumberField source="stage" />

                        <ShowButton label="" />
                        {permissions?.isAdmin && <EditButton label="" />}
                        {permissions?.isAdmin && <DeleteButton label="" />}
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};
