import React from "react";
import {Logout, usePermissions, UserMenu as RaUserMenu} from "react-admin";
import {Link} from "react-router-dom";

import UserIcon from "@mui/icons-material/Person";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

export const UserMenu: React.FC = () => {
    const {permissions} = usePermissions();
    if (!permissions) return null;
    const {isStaff, email} = permissions;

    return (
        <RaUserMenu>
            <MenuItem
                component={Link}
                to={isStaff ? `/staff/${email}/show` : `/admins/${email}/show`}
            >
                <ListItemIcon>
                    <UserIcon />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </MenuItem>
            <Logout />
        </RaUserMenu>
    );
};
