import {AsYouType, isValidPhoneNumber} from "libphonenumber-js/min";

const phoneDefaultConfig = {defaultCountry: "PL", defaultCallingCode: "+48"} as const;

export function formatPhoneNumber(phoneNumber = ""): string {
    return new AsYouType(phoneDefaultConfig).input(phoneNumber);
}

export function validatePhoneNumber(phoneNumber: string): boolean {
    if (!phoneNumber) return true;
    return isValidPhoneNumber(phoneNumber, phoneDefaultConfig);
}
