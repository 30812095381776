import {required, Validator, email, minLength, maxLength} from "react-admin";

import {emptyIfRelatedFalsey, phone, time} from "../../validators";

export const validateId: Array<Validator> = [required(), email()];
export const validateFirstName: Array<Validator> = [required(), minLength(2), maxLength(64)];
export const validateLastName: Array<Validator> = [required(), minLength(2), maxLength(64)];
// TODO: validate the end time is after start time
export const validateWorkHours: (relatedFieldPath: string) => Array<Validator> = (
    relatedFieldPath: string
) => [emptyIfRelatedFalsey(relatedFieldPath), time()];
export const validateVisitTime: Array<Validator> = [];
export const validatePhoto: Array<Validator> = [];
export const validatePhone: Array<Validator> = [required(), phone()];
export const validateDescription: Array<Validator> = [];
export const validateAddress: Array<Validator> = [];
export const validateCity: Array<Validator> = [];
