import * as React from "react";
import {
    List,
    Datagrid,
    ShowButton,
    EditButton,
    DeleteButton,
    EmailField,
    TextField,
    usePermissions,
    useRecordContext
} from "react-admin";

const StaffEditButton: React.FC = () => {
    const record = useRecordContext();
    const {
        permissions: {email, isAdmin}
    } = usePermissions();
    if (!isAdmin && record?.id !== email) return null;
    return <EditButton label="" />;
};

const StaffDeleteButton: React.FC = () => {
    const record = useRecordContext();
    const {
        permissions: {email, isAdmin}
    } = usePermissions();
    if (!isAdmin && record?.id !== email) return null;
    return <DeleteButton label="" />;
};

export const StaffList: React.FC = () => (
    <List>
        <Datagrid>
            <EmailField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <ShowButton label="" />
            <StaffEditButton />
            <StaffDeleteButton />
        </Datagrid>
    </List>
);
