import {RichTextInput} from "ra-input-rich-text";
import * as React from "react";
import {Edit, SimpleForm, TextInput} from "react-admin";

import {validateBody} from "../visit-comment.validation";

export const VisitCommentEdit: React.FC = () => (
    <Edit mutationMode="pessimistic" redirect={false}>
        <SimpleForm>
            <TextInput source="visitId" disabled />
            <RichTextInput name="body" source="body" fullWidth={true} validate={validateBody} />
        </SimpleForm>
    </Edit>
);
