import {IVisit} from "$types/visit.interface";

import {maxLength, required, Validator} from "react-admin";

import {EventInput} from "@fullcalendar/core";

import {higherThanRelated, minDate, requiredIfRelatedFalsey} from "../../validators";

export const validateNotAvailable: Array<Validator> = [];
export const validateStatus: Array<Validator> = [required()];
export const validateTitle: Array<Validator> = [maxLength(128)];
export const validateStart: Array<Validator> = [required(), minDate(Date.now())];
export const validateEnd: Array<Validator> = [required(), higherThanRelated("start")];
export const validateUserId: Array<Validator> = [requiredIfRelatedFalsey("notAvailable")]; // user id required, if regular visit
export const validateStaffEmail: Array<Validator> = [required()];

// TODO: validate not outside of business hours
//  ensure no edits that would overlap with other events
export function parseVisitToEvent(visit: IVisit): EventInput {
    /**
     * Cristian Sanchez function hashing string to hexadecimal colour
     * @param {string} email Email to hash
     * @returns {string} Hexadecimal colour that will always be the same given the same input
     */
    function emailToColorHashing(email: string): `#${string}` {
        let hash = 0;
        for (let i = 0; i < email.length; i++) {
            hash = email.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour: `#${string}` = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
    }
    return {
        ...visit,
        id: String(visit.id),
        display: visit.notAvailable ? "background" : undefined,
        color: emailToColorHashing(visit.staffEmail)
    };
}
