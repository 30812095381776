import * as React from "react";
import {Create} from "react-admin";

import {ExerciseForm} from "../forms";

export const ExerciseCreate: React.FC = () => (
    <Create>
        <ExerciseForm />
    </Create>
);
