import {choices, email, maxLength, minLength, minValue, required, Validator} from "react-admin";

import {maxDate, phone} from "../../validators";

export enum GENDER_ENUM {
    MALE = "MALE",
    FEMALE = "FEMALE",
    UNSPECIFIED = "UNSPECIFIED"
}

export const validateFirstName: Array<Validator> = [required(), minLength(2), maxLength(64)];
export const validateLastName: Array<Validator> = [required(), minLength(2), maxLength(64)];
export const validateDescription: Array<Validator> = [];
export const validateEmail: Array<Validator> = [required(), email()];
export const validateBirthDate: Array<Validator> = [maxDate(Date.now())];
export const validateHeight: Array<Validator> = [minValue(1)];
export const validateWeight: Array<Validator> = [minValue(1)];
export const validatePhone: Array<Validator> = [required(), phone()];
export const validateGender: Array<Validator> = [required(), choices(Object.keys(GENDER_ENUM))];
export const validateBoughtVisitsCount: Array<Validator> = [required(), minValue(0)];
