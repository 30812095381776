import React from "react";
import {Resource} from "react-admin";

import UserIcon from "@mui/icons-material/Person";

import {UserCreate} from "./views/user.create";
import {UserEdit} from "./views/user.edit";
import {UserList} from "./views/user.list";
import {UserShow} from "./views/user.show";

export function userResource({isAdmin}: {isAdmin?: boolean}): JSX.Element {
    return (
        <Resource
            icon={UserIcon}
            name="users"
            show={UserShow}
            list={UserList}
            create={isAdmin ? UserCreate : void 0}
            edit={isAdmin ? UserEdit : void 0}
        />
    );
}
