export enum EWeekDays {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6
}

export type TWorkHours = Partial<
    Record<keyof typeof EWeekDays, {startTime: string; endTime: string}>
>;

export interface IWorkSpec {
    startTime: string;
    endTime: string;
    daysOfWeek: Array<EWeekDays>;
}

export function formatWorkHoursToWorkSpec(
    hours: TWorkHours | null = null
): Array<IWorkSpec> | false {
    if (!hours) return false;
    return Object.entries(hours).map(([day, {startTime, endTime}]) => ({
        daysOfWeek: [EWeekDays[day as keyof typeof EWeekDays]],
        startTime,
        endTime
    }));
}

export function validateTimeString(time: string): boolean {
    return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}
