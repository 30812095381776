import {useResourceDefinitions} from "ra-core";
import React from "react";
import {Title, useRedirect, useTranslate} from "react-admin";

import DefaultIcon from "@mui/icons-material/ViewList";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";

import packageJson from "../../package.json";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 200,
    height: 220,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
}));

export const HomePage: React.FC = () => {
    const resources = useResourceDefinitions();
    const translate = useTranslate();
    const redirect = useRedirect();

    return (
        <Box sx={{marginTop: "30px", maxWidth: "900px"}}>
            <Title title={`${translate("ra.page.dashboard")} v${packageJson.version}`} />
            <Grid container spacing={2}>
                {Object.values(resources)
                    .filter(({hasList}) => hasList)
                    .map(({name, icon: ResourceIcon = DefaultIcon}) => (
                        <Grid item key={name}>
                            <Item onClick={(): void => redirect(name)}>
                                <span>{translate(`resources.${name}.name`, {smart_count: 2})}</span>
                                <div>
                                    <ResourceIcon sx={{marginTop: "30px", fontSize: 140}} />
                                </div>
                            </Item>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};
