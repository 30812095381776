import get from "lodash.get";
import isEmpty from "lodash.isempty";
import {RaRecord, ValidationErrorMessage, Validator} from "react-admin";

import {validateTimeString} from "../utils/calendar";
import {validatePhoneNumber} from "../utils/phone-numbers";
import {getMessage, memoize, MessageFunc} from "./utils";

export const minDate: (min: number | Date, message?: string) => Validator = memoize(
    (min: number | Date, message: string | MessageFunc = "ra.validation.date"): Validator =>
        (value: string, values): ValidationErrorMessage | undefined =>
            !isEmpty(value) && new Date(value).getTime() <= min
                ? getMessage(message, null, value, values)
                : undefined
);

export const maxDate: (max: number, message?: string) => Validator = memoize(
    (max: number, message: string | MessageFunc = "ra.validation.date"): Validator =>
        (value: string, values): ValidationErrorMessage | undefined =>
            !isEmpty(value) && new Date(value).getTime() >= max
                ? getMessage(message, null, value, values)
                : undefined
);

export const phone: (message?: string) => Validator = memoize(
    (message: string | MessageFunc = "ra.validation.phone"): Validator =>
        (value: string, values): ValidationErrorMessage | undefined =>
            !isEmpty(value) && !validatePhoneNumber(value)
                ? getMessage(message, null, value, values)
                : undefined
);

export const time: (message?: string) => Validator = memoize(
    (message: string | MessageFunc = "ra.validate.time"): Validator =>
        (value: string, values): ValidationErrorMessage | undefined =>
            !isEmpty(value) && !validateTimeString(value)
                ? getMessage(message, null, value, values)
                : undefined
);

export const youtubeUrl: (message?: string) => Validator = memoize(
    (message: string | MessageFunc = "ra.validation.url"): Validator =>
        (value: string, values): ValidationErrorMessage | undefined =>
            !isEmpty(value) &&
            !value.match(
                /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
            )
                ? getMessage(message, null, value, values)
                : undefined
);

export const integer: (message?: string) => Validator = memoize(
    (message: string | MessageFunc = "ra.validation.integer"): Validator =>
        (value: number, values): ValidationErrorMessage | undefined =>
            value && !Number.isInteger(value) ? getMessage(message, null, value, values) : undefined
);

// TODO: use function to to pass related field into the error message function
export const emptyIfRelatedFalsey: (relatedFieldPath: string, message?: string) => Validator =
    memoize(
        (
                relatedFieldPath: string,
                message: string | MessageFunc = "ra.validation.required-related"
            ): Validator =>
            (value: string, values: RaRecord): ValidationErrorMessage | undefined =>
                !get(values, relatedFieldPath) && value
                    ? getMessage(message, null, value, values)
                    : undefined
    );

export const requiredIfRelatedTruthy: (relatedFieldPath: string, message?: string) => Validator =
    memoize(
        (
                relatedFieldPath: string,
                message: string | MessageFunc = "ra.validation.required"
            ): Validator =>
            (value: string, values: RaRecord): ValidationErrorMessage | undefined =>
                get(values, relatedFieldPath) && !value
                    ? getMessage(message, null, value, values)
                    : undefined
    );

export const requiredIfRelatedFalsey: (relatedFieldPath: string, message?: string) => Validator =
    memoize(
        (
                relatedFieldPath: string,
                message: string | MessageFunc = "ra.validation.required"
            ): Validator =>
            (value: string, values: RaRecord): ValidationErrorMessage | undefined =>
                !get(values, relatedFieldPath) && !value
                    ? getMessage(message, null, value, values)
                    : undefined
    );

export const higherThanRelated: (relatedFieldPath: string, message?: string) => Validator = memoize(
    (
            relatedFieldPath: string,
            message: string | MessageFunc = "ra.validation.minValue"
        ): Validator =>
        (value: string, values: RaRecord): ValidationErrorMessage | undefined =>
            value <= get(values, relatedFieldPath)
                ? getMessage(message, {min: get(values, relatedFieldPath)}, value, values)
                : undefined
);

export const smallerThanRelated: (relatedFieldPath: string, message?: string) => Validator =
    memoize(
        (
                relatedFieldPath: string,
                message: string | MessageFunc = "ra.validation.maxValue"
            ): Validator =>
            (value: string, values: RaRecord): ValidationErrorMessage | undefined =>
                value >= get(values, relatedFieldPath)
                    ? getMessage(message, {max: get(values, relatedFieldPath)}, value, values)
                    : undefined
    );
