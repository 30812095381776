import * as React from "react";
import {Edit} from "react-admin";

import {ExerciseForm} from "../forms";

export const ExerciseEdit: React.FC = () => {
    return (
        <Edit mutationMode="pessimistic">
            <ExerciseForm />
        </Edit>
    );
};
