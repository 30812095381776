import React from "react";
import {FormTab, ImageField, ImageInput, NumberInput, TabbedForm, TextInput} from "react-admin";

import {TimeInput} from "../../../pages/components/time-input";
import {
    validateAddress,
    validateCity,
    validateDescription,
    validateFirstName,
    validateId,
    validateLastName,
    validatePhone,
    validatePhoto,
    validateVisitTime,
    validateWorkHours
} from "../staff.validation";

export const StaffForm: React.FC = () => {
    return (
        <TabbedForm>
            <FormTab label="form.tab.details">
                <ImageInput
                    source="photo"
                    accept="image/*"
                    maxSize={2097152 /*2MB size*/}
                    validate={validatePhoto}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="id" validate={validateId} fullWidth />
                <TextInput source="firstname" validate={validateFirstName} fullWidth />
                <TextInput source="lastname" validate={validateLastName} fullWidth />
                <TextInput source="phone" validate={validatePhone} fullWidth />
                <TextInput source="address" validate={validateAddress} fullWidth />
                <TextInput source="city" validate={validateCity} fullWidth />
                <TextInput
                    source="description"
                    validate={validateDescription}
                    multiline
                    fullWidth
                    minRows={5}
                />
            </FormTab>
            <FormTab label="form.tab.business-hours">
                <NumberInput
                    source="visitTime"
                    validate={validateVisitTime}
                    defaultValue={30}
                    min={5}
                    step={5}
                    helperText="resources.staff.helperTexts.visitTime"
                />
                <div>
                    <TimeInput
                        source="workHours.monday.startTime"
                        validate={validateWorkHours("workHours.monday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.monday.endTime"
                        validate={validateWorkHours("workHours.monday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.tuesday.startTime"
                        validate={validateWorkHours("workHours.tuesday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.tuesday.endTime"
                        validate={validateWorkHours("workHours.tuesday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.wednesday.startTime"
                        validate={validateWorkHours("workHours.wednesday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.wednesday.endTime"
                        validate={validateWorkHours("workHours.wednesday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.thursday.startTime"
                        validate={validateWorkHours("workHours.thursday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.thursday.endTime"
                        validate={validateWorkHours("workHours.thursday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.friday.startTime"
                        validate={validateWorkHours("workHours.friday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.friday.endTime"
                        validate={validateWorkHours("workHours.friday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.saturday.startTime"
                        validate={validateWorkHours("workHours.saturday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.saturday.endTime"
                        validate={validateWorkHours("workHours.saturday.startTime")}
                    />
                </div>
                <div>
                    <TimeInput
                        source="workHours.sunday.startTime"
                        validate={validateWorkHours("workHours.sunday.endTime")}
                        style={{display: "inline-flex", float: "left", marginRight: "10px"}}
                    />
                    <TimeInput
                        source="workHours.sunday.endTime"
                        validate={validateWorkHours("workHours.sunday.startTime")}
                    />
                </div>
            </FormTab>
        </TabbedForm>
    );
};
