import * as React from "react";
import {useEffect} from "react";
import {BooleanInput} from "react-admin";
import {useFormContext, useFormState} from "react-hook-form";

export const VisitIsRescheduledInput: React.FC = () => {
    const {setValue} = useFormContext();
    const {dirtyFields, defaultValues} = useFormState();

    useEffect(() => {
        if (dirtyFields.start) setValue("isRescheduled", true);
        else setValue("isRescheduled", defaultValues?.isRescheduled);
    }, [defaultValues, dirtyFields.start, setValue]);

    return <BooleanInput source="isRescheduled" defaultValue={false} options={{}} disabled />;
};
