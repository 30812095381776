import React from "react";
import {FunctionField, RaRecord} from "react-admin";

import Chip from "@mui/material/Chip";
import {styled} from "@mui/material/styles";

const StyledChip = styled(Chip, {
    overridesResolver: (props, styles) => styles.root
})({margin: 4, cursor: "inherit", "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.12)"}});

export const ConcatenatedField: React.FC<{sources: Array<string>}> = ({sources}) => {
    return (
        <FunctionField
            render={(record: RaRecord): JSX.Element => (
                <StyledChip
                    label={sources
                        .map((source: string): string => String(record[source]))
                        .join(" ")}
                />
            )}
        />
    );
};
