import * as React from "react";
import {SaveButton, SimpleForm, Toolbar, Identifier, Create, TextInput} from "react-admin";

import {HiddenInput} from "../../../custom-inputs/HiddenInput";
import {validateBody} from "../visit-comment.validation";

export const VisitCommentQuickCreate: React.FC<{visitId: Identifier; userId: Identifier}> = ({
    visitId,
    userId
}) => (
    <Create resource="visitComments">
        <SimpleForm
            toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }
        >
            <TextInput source="body" fullWidth={true} validate={validateBody} />
            <HiddenInput source="visitId" value={visitId} />
            <HiddenInput source="userId" value={userId} />
        </SimpleForm>
    </Create>
);
