import * as React from "react";
import {
    Show,
    TextField,
    SimpleShowLayout,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    useTranslate,
    ImageField,
    NumberField
} from "react-admin";

export const DiseaseShow: React.FC = () => {
    const translate = useTranslate();
    return (
        <Show>
            <SimpleShowLayout>
                <ImageField source="icon.src" title="icon.title" />
                <TextField source="name" />
                <TextField source="description" />
                <ReferenceManyField
                    reference="exerciseSets"
                    target="diseaseId"
                    label={translate("resources.exerciseSets.name", {smart_count: 2})}
                >
                    <SingleFieldList linkType="show">
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField>
                <NumberField source="discounts.newPackagesPrice" />
                <NumberField source="discounts.newVisitsPrice" />
            </SimpleShowLayout>
        </Show>
    );
};
