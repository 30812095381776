import {IUser} from "$types/user.interface";

import {query, where} from "firebase/firestore";
import * as React from "react";
import {useEffect} from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    usePermissions,
    useTranslate
} from "react-admin";
import {
    FireStoreCollectionRef,
    FireStoreQuery
} from "react-admin-firebase/dist/misc/firebase-models";
import {UseFormReturn} from "react-hook-form/dist/types";
import {useSearchParams} from "react-router-dom";

export const UserFilter: React.FC<{form: UseFormReturn}> = ({form}) => {
    const {
        setValue,
        formState: {isDirty}
    } = form;
    const {permissions} = usePermissions();
    const translate = useTranslate();

    const [search] = useSearchParams();
    const filterValues = search.get("filter");
    const defaultUserIdFromQuery = filterValues ? JSON.parse(filterValues).userId ?? null : null;

    useEffect(() => {
        if (!isDirty && defaultUserIdFromQuery) setValue("userId", defaultUserIdFromQuery);
    }, [defaultUserIdFromQuery, isDirty, setValue]);

    if (!permissions) return null;
    return (
        <ReferenceInput
            reference="users"
            source="userId"
            filter={{
                collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery => {
                    return permissions.isAdmin
                        ? ref
                        : query(ref, where("staff", "array-contains", permissions.email));
                }
            }}
            alwaysOn
            helperText={false}
        >
            <AutocompleteInput
                defaultValue={defaultUserIdFromQuery}
                optionText={(record: Partial<IUser>): string => record.email ?? "Anonymous"}
                label={translate("resources.users.name", {smart_count: 1})}
                filterToQuery={(q): Partial<IUser> => ({email: q})}
                validate={[required()]}
                sx={{width: "280px", marginRight: "16px"}}
                helperText={false}
            />
        </ReferenceInput>
    );
};
