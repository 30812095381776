import * as React from "react";
import {AppBar, AppBarProps, Layout, LayoutProps} from "react-admin";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {Menu} from "./components/Menu";
import {UserMenu} from "./components/UserMenu";
import {BackButton} from "./components/back-arrow";
import {LocaleSelector} from "./components/locale-selector";

const MyAppBar: React.FC<AppBarProps> = (props) => (
    <AppBar {...props} style={{alignItems: "stretch"}} userMenu={<UserMenu />}>
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title" />
        </Box>

        <LocaleSelector />
        <BackButton />
    </AppBar>
);

const MyLayout: React.FC<LayoutProps> = (props) => (
    <Layout {...props} appBar={MyAppBar} menu={Menu} />
);

export default MyLayout;
