import * as React from "react";
import {Edit} from "react-admin";

import {StaffForm} from "../forms";

export const StaffEdit: React.FC = () => (
    <Edit mutationMode="pessimistic">
        <StaffForm />
    </Edit>
);
