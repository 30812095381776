import {IUser} from "$types/user.interface";

import {query, where} from "firebase/firestore";
import React, {useEffect} from "react";
import {AutocompleteInput, ReferenceInput, usePermissions} from "react-admin";
import {FireStoreCollectionRef} from "react-admin-firebase/dist/misc/firebase-models";
import {FireStoreQuery} from "react-admin-firebase/src/misc/firebase-models";
import {useFormContext} from "react-hook-form";

import {validateUserId} from "../visit.validation";

export const VisitUserIdInput: React.FC = () => {
    const {resetField, watch} = useFormContext();
    const notAvailable = watch("notAvailable");
    const staffEmailFromForm = watch("staffEmail");
    const {
        permissions: {isAdmin, email}
    } = usePermissions();

    useEffect(() => {
        if (notAvailable) resetField("userId");
    }, [notAvailable, resetField]);
    if (notAvailable || !staffEmailFromForm) return null;

    return (
        <ReferenceInput
            // refresh collection query filters after staff email changes
            key={staffEmailFromForm}
            source="userId"
            reference="users"
            validate={validateUserId}
            required={true}
            filter={{
                collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery => {
                    // admin gets to assign staff manually in the form, so the choice is based on the form instead of logged in user
                    return query(
                        ref,
                        where("staff", "array-contains", isAdmin ? staffEmailFromForm : email)
                    );
                }
            }}
        >
            <AutocompleteInput
                optionText="email"
                filterToQuery={(q: string): Partial<IUser> => ({email: q})}
            />
        </ReferenceInput>
    );
};
