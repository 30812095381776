/* eslint-disable prettier/prettier */
import {initializeApp, FirebaseApp} from "firebase/app";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {connectStorageEmulator, getStorage} from "firebase/storage";
import type {RAFirebaseOptions} from "react-admin-firebase";
import {IStaff} from "$types/staff.interface";

export interface IFirebaseConfig {
    apiKey: string; // required to run
    appId: string; // required to run
    authDomain: `${string}.firebaseapp.com`; // required to run auth providers
    databaseURL?: string;
    projectId: string; // required to run
    storageBucket: string; // required to run storage used for default bucket
    messagingSenderId?: string;
}

/**
 * Function that initializes firebase
 * @returns {Promise<{adminOptions: RAFirebaseOptions, config: IFirebaseConfig}>} configs for react-admin-firebase data and auth providers
 */
export function initializeFirebaseService(): Promise<{
    adminOptions: RAFirebaseOptions;
    config: IFirebaseConfig;
}> {
    console.log("initializeFirebaseService");

    const isDev = process.env.NODE_ENV?.startsWith("development") ?? false; // development or development_emulated

    const firebaseAdminOptions = (app: FirebaseApp): RAFirebaseOptions => ({
        app,
        logging: isDev,
        // metaFieldCasing: "camel",
        // disableMeta: false,
        transformToDb: <T extends Record<string, unknown>>(
            resourceName: string,
            documentData: T
        ): Partial<T> => {
            if (resourceName === "staff") {
                const workHours = (documentData as unknown as IStaff).workHours;
                if (workHours) {
                    (documentData as unknown as IStaff).workHours = Object.fromEntries(Object
                        .entries(workHours)
                        .filter(([, hours]) => Boolean(hours?.startTime && hours.endTime))
                    )
                }
            }
            // remove undefined fields from the document, since firestore does not accept undefined as a field value
            return Object.fromEntries(
                Object.entries(documentData).filter(([, val]) => val !== undefined)
            ) as Partial<T>;
        }
    });

    if (isDev) {
        const config: IFirebaseConfig = {
            projectId: "emulated-project",
            appId: "emulated-project",
            apiKey: "SG.emulated-project",
            authDomain: "emulated-project.firebaseapp.com",
            storageBucket: "emulated-project"
        };

        const app = initializeApp(config);
        connectStorageEmulator(getStorage(), "localhost", 9199);
        connectFirestoreEmulator(getFirestore(), "localhost", 8080);
        connectAuthEmulator(getAuth(), "http://localhost:9099");

        // we are not actually calling functions module directly via admin panel so no need to connect the emulator
        // import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
        // connectFunctionsEmulator(getFunctions(app, "europe-central2"), "localhost", 5001);

        return Promise.resolve({
            adminOptions: firebaseAdminOptions(app),
            config
        });
    }
    return fetch("/__/firebase/init.json")
        .then((res) => res.json())
        .then((config: IFirebaseConfig) => ({
            adminOptions: firebaseAdminOptions(initializeApp(config)),
            config
        }));
}
