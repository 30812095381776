import * as React from "react";
import {Create} from "react-admin";

import {ExerciseSetForm} from "../forms";

export const ExerciseSetCreate: React.FC = () => (
    <Create>
        <ExerciseSetForm />
    </Create>
);
