import * as React from "react";
import {useNavigate} from "react-router";

import BackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

export const BackButton: React.FC = () => {
    const navigate = useNavigate();
    function handleClick(): void {
        navigate(-1);
    }
    return (
        <IconButton color="inherit" onClick={handleClick}>
            <BackIcon />
        </IconButton>
    );
};
