import {IVisit} from "$types/visit.interface";

import {query, where} from "firebase/firestore";
import {Button} from "ra-ui-materialui";
import React, {useState} from "react";
import {
    Show,
    TabbedShowLayout,
    TextField,
    DateField,
    ReferenceField,
    BooleanField,
    Tab,
    ReferenceManyField,
    Datagrid,
    RichTextField,
    DeleteButton,
    TopToolbar,
    EditButton,
    useGetRecordId,
    useTranslate,
    FunctionField,
    useRecordContext
} from "react-admin";
import {
    FireStoreCollectionRef,
    FireStoreQuery
} from "react-admin-firebase/dist/misc/firebase-models";

import ContentAdd from "@mui/icons-material/Add";
import CallIcon from "@mui/icons-material/Call";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

import {generateVisitLink} from "../../../utils/jitsi";
import {VisitCommentEditButton} from "../../visit-comment/buttons/visit-comment-edit.button";
import {VisitCommentQuickCreate} from "../../visit-comment/views/visit-comment.quick-create";

const VisitShowActions: React.FC = () => {
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const translate = useTranslate();
    const record = useRecordContext() as IVisit | void;

    if (!record) return null;

    return (
        <TopToolbar>
            {record.userId && record.status !== "CANCELLED" && (
                <Button
                    component={Link}
                    href={generateVisitLink(String(record.id))}
                    label={translate("resources.visits.buttons.join-meeting")}
                >
                    <CallIcon />
                </Button>
            )}
            <Button
                onClick={(): void => setShowCreateDialog(true)}
                label={translate("quick-create.comment")}
            >
                <ContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showCreateDialog}
                onClose={(): void => setShowCreateDialog(false)}
                aria-label={translate("quick-create.comment")}
            >
                <DialogTitle>{translate("quick-create.comment")}</DialogTitle>
                <VisitCommentQuickCreate visitId={record.id} userId={record.userId} />
            </Dialog>
            <EditButton />
            <DeleteButton />
        </TopToolbar>
    );
};

export const VisitShow: React.FC = () => {
    const visitId = useGetRecordId();
    const translate = useTranslate();

    return (
        <Show actions={<VisitShowActions />}>
            <TabbedShowLayout>
                <Tab label="form.tab.summary">
                    <BooleanField source="isRescheduled" />
                    <FunctionField
                        label="resources.visits.fields.status"
                        render={(record: IVisit | void): string | void =>
                            record?.status
                                ? translate(`resources.visits.status.${record.status}`)
                                : void 0
                        }
                    />
                    <TextField source="title" />
                    <DateField source="start" showTime={true} />
                    <DateField source="end" showTime={true} />
                    <ReferenceField reference="users" source="userId" link="show">
                        <TextField source="email" />
                    </ReferenceField>
                    <ReferenceField reference="staff" source="staffEmail" link="show">
                        <TextField source="id" />
                    </ReferenceField>
                </Tab>
                <Tab label="form.tab.comments">
                    <ReferenceManyField
                        reference="visitComments"
                        target="visitId"
                        label=""
                        filter={{
                            collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery =>
                                query(ref, where("visitId", "==", visitId))
                        }}
                        sort={{field: "createdate", order: "DESC"}}
                    >
                        <Datagrid>
                            <TextField source="createdby" />
                            <DateField showTime={true} source="createdate" />
                            <RichTextField source="body" />
                            <VisitCommentEditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
