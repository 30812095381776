import {IStaff} from "$types/staff.interface";

import * as React from "react";
import {
    Show,
    EmailField,
    TextField,
    ImageField,
    TabbedShowLayout,
    Tab,
    usePermissions,
    FunctionField,
    useGetRecordId
} from "react-admin";

import {StaffWorkHoursField} from "../fields/StaffWorkHours.field";

export const StaffShow: React.FC = () => {
    const {permissions} = usePermissions();
    const staffId = useGetRecordId();
    const isMyProfile = permissions?.email === staffId;

    return (
        <Show
            title={isMyProfile ? "My Profile" : undefined}
            actions={isMyProfile || permissions?.isAdmin ? void 0 : false}
        >
            <TabbedShowLayout>
                <Tab label="form.tab.public-details">
                    <EmailField source="id" />
                    <TextField source="firstname" />
                    <TextField source="lastname" />
                    <ImageField source="photo.src" title="photo.title" />
                </Tab>
                <Tab label="form.tab.business-hours">
                    <FunctionField
                        label="resources.staff.fields.visitTime"
                        render={(record: IStaff | void): string => `${record?.visitTime ?? 30} min`}
                    />
                    <StaffWorkHoursField />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
