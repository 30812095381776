import {IVisit} from "$types/visit.interface";

import React from "react";
import {
    BooleanInput,
    DateTimeInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    Create
} from "react-admin";

import {ViewApi} from "@fullcalendar/core";

import {HiddenInput} from "../../../custom-inputs/HiddenInput";
import {VisitStaffEmailInput, VisitUserIdInput} from "../inputs";
import {
    parseVisitToEvent,
    validateEnd,
    validateNotAvailable,
    validateStart,
    validateTitle
} from "../visit.validation";

export const VisitQuickCreate: React.FC<{
    onSave: (data: Partial<IVisit>) => void;
    initialEventData: {start: Date; end: Date; view: ViewApi};
}> = ({onSave, initialEventData: selectInfo}): JSX.Element => {
    return (
        <Create
            resource="visits"
            redirect={false}
            mutationOptions={{
                onSettled: (visit: IVisit | void): void => {
                    if (!visit) return;
                    onSave(visit);
                    const calendarApi = selectInfo?.view.calendar;
                    if (!calendarApi) return;
                    calendarApi.unselect(); // clear date selection
                    calendarApi.addEvent(parseVisitToEvent(visit));
                }
            }}
        >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }
            >
                {/*visits created by a doctor are confirmed by default*/}
                <HiddenInput source="status" value="CONFIRMED" />
                <BooleanInput source="notAvailable" validate={validateNotAvailable} options={{}} />
                <TextInput source="title" validate={validateTitle} />
                <DateTimeInput
                    source="start"
                    defaultValue={selectInfo?.start}
                    validate={validateStart}
                />
                <DateTimeInput source="end" defaultValue={selectInfo?.end} validate={validateEnd} />
                <VisitStaffEmailInput />
                <VisitUserIdInput />
            </SimpleForm>
        </Create>
    );
};
