import * as React from "react";
import {
    List,
    Datagrid,
    ShowButton,
    EditButton,
    DeleteButton,
    TextField,
    ReferenceField,
    usePermissions,
    TextInput
} from "react-admin";

export const ExerciseList: React.FC = () => {
    const {permissions} = usePermissions();
    return (
        <List filters={[<TextInput source="name" key="name" alwaysOn />]}>
            <Datagrid bulkActionButtons={permissions?.isAdmin ? void 0 : false}>
                <ReferenceField
                    source="exerciseSetId"
                    reference="exerciseSets"
                    link="show"
                    sortable={false}
                />
                <TextField source="name" />
                <TextField source="executionFrequency" />

                <ShowButton label="" />
                {permissions?.isAdmin && <EditButton label="" />}
                {permissions?.isAdmin && <DeleteButton label="" />}
            </Datagrid>
        </List>
    );
};
