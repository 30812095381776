import React from "react";
import {Resource} from "react-admin";

import AddChartIcon from "@mui/icons-material/Addchart";

import {ProgressStatsList} from "./views/progress-stats.list";

export function progressStatsResource(): JSX.Element {
    return <Resource icon={AddChartIcon} name="progressStats" list={ProgressStatsList} />;
}
