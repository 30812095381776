import {query, where} from "firebase/firestore";
import {SimpleFormProps} from "ra-ui-materialui/dist/cjs/form/SimpleForm";
import React from "react";
import {
    Identifier,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    usePermissions,
    useTranslate
} from "react-admin";
import {FireStoreCollectionRef} from "react-admin-firebase/dist/misc/firebase-models";
import {FireStoreQuery} from "react-admin-firebase/src/misc/firebase-models";
import {useFormContext, useWatch} from "react-hook-form";

import {ExerciseSetRecordRepresentation} from "../../exercise-set";
import {
    validateId,
    validateDiseaseIds,
    validateExerciseSetIds
} from "../user-recommendation.validation";

export const UserInput: React.FC<{defaultValue?: Identifier}> = ({defaultValue}) => {
    const translate = useTranslate();
    const formContext = useFormContext();
    const {permissions} = usePermissions();

    if (!permissions) return null;
    return (
        <ReferenceInput
            reference="users"
            source="id"
            filter={{
                collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery =>
                    // can only call in on max 10 elements
                    permissions.isAdmin
                        ? ref
                        : query(ref, where("staff", "array-contains", permissions.email))
            }}
        >
            <SelectInput
                optionText="email"
                label={translate("resources.users.name", {smart_count: 1})}
                validate={validateId}
                defaultValue={defaultValue}
                disabled={Boolean(defaultValue || formContext?.formState.defaultValues?.id)}
                fullWidth
            />
        </ReferenceInput>
    );
};

export const UserRecommendationForm: React.FC<
    Omit<SimpleFormProps, "children"> & {userId?: Identifier}
> = ({userId, ...props}) => {
    const translate = useTranslate();

    return (
        <SimpleForm {...props}>
            <UserInput defaultValue={userId} />
            <ReferenceArrayInput
                source="diseaseIds"
                reference="diseases"
                defaultValue={[]}
                fullWidth
            >
                <SelectArrayInput
                    optionText="name"
                    label={translate("resources.diseases.name", {smart_count: 2})}
                    validate={validateDiseaseIds}
                    options={{autoWidth: false}}
                    fullWidth
                />
            </ReferenceArrayInput>
            <ExerciseSetIdsInput />
        </SimpleForm>
    );
};

const ExerciseSetIdsInput: React.FC = () => {
    const translate = useTranslate();
    const diseaseIds = useWatch({name: "diseaseIds"});

    const canFilterDiseases = diseaseIds?.length && diseaseIds.length <= 10;
    return (
        <ReferenceArrayInput
            key={diseaseIds?.join("-")}
            source="exerciseSetIds"
            reference="exerciseSets"
            defaultValue={[]}
            filter={{
                collectionQuery: (ref: FireStoreCollectionRef): FireStoreQuery =>
                    // can only call in on max 10 elements
                    canFilterDiseases
                        ? query(ref, where("diseaseId", "in", diseaseIds.slice(0, 10)))
                        : ref
            }}
        >
            <SelectArrayInput
                optionText={<ExerciseSetRecordRepresentation />}
                label={translate("resources.exerciseSets.name", {smart_count: 2})}
                validate={validateExerciseSetIds}
                options={{autoWidth: false}}
                fullWidth
            />
        </ReferenceArrayInput>
    );
};
