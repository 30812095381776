import React from "react";
import {Resource} from "react-admin";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import {VisitCreate} from "./views/visit.create";
import {VisitEdit} from "./views/visit.edit";
import {VisitList} from "./views/visit.list";
import {VisitShow} from "./views/visit.show";

export function visitResource(): JSX.Element {
    return (
        <Resource
            icon={CalendarMonthIcon}
            name="visits"
            create={VisitCreate}
            show={VisitShow}
            edit={VisitEdit}
            list={VisitList}
        />
    );
}
