import * as React from "react";
import {BooleanInput, Create, DateTimeInput, SimpleForm, TextInput} from "react-admin";

import {HiddenInput} from "../../../custom-inputs/HiddenInput";
import {VisitStaffEmailInput, VisitUserIdInput} from "../inputs";
import {validateEnd, validateNotAvailable, validateStart, validateTitle} from "../visit.validation";

export const VisitCreate: React.FC = () => {
    return (
        <Create>
            <SimpleForm>
                <HiddenInput source="status" value="NEW" />
                <BooleanInput source="notAvailable" validate={validateNotAvailable} options={{}} />
                <TextInput name="title" source="title" validate={validateTitle} />
                <DateTimeInput name="start" source="start" validate={validateStart} />
                <DateTimeInput name="end" source="end" validate={validateEnd} />
                <VisitStaffEmailInput />
                <VisitUserIdInput />
            </SimpleForm>
        </Create>
    );
};
