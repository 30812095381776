import * as React from "react";
import {Create} from "react-admin";

import {DiseaseForm} from "../forms";

export const DiseaseCreate: React.FC = () => (
    <Create>
        <DiseaseForm />
    </Create>
);
