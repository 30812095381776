import {IUser} from "$types/user.interface";

import React from "react";
import {
    Datagrid,
    DateField,
    FunctionField,
    ListBase,
    NumberField,
    Pagination,
    ReferenceField,
    usePermissions
} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";

import Card from "@mui/material/Card";

import {UserFilter} from "../../user/user.filter";

export const ProgressStatsList: React.FC = () => {
    const form = useForm();
    const {permissions} = usePermissions();
    const userId = form.watch("userId");
    if (!permissions) return null;

    if (!userId)
        return (
            <FormProvider {...form}>
                <UserFilter form={form} />
            </FormProvider>
        );
    return (
        <ListBase key={userId} filter={{userId}} sort={{field: "date", order: "DESC"}}>
            <FormProvider {...form}>
                <UserFilter form={form} />
            </FormProvider>
            <Card>
                <Datagrid>
                    <ReferenceField reference="users" source="userId" link="show">
                        <FunctionField
                            render={(record: IUser | void): string =>
                                record ? `${record.firstname} ${record.lastname}` : "N/A"
                            }
                        />
                    </ReferenceField>
                    <NumberField source="timeSpent" />
                    <NumberField source="exerciseQuantity" />
                    <DateField source="startDate" />
                    <DateField source="endDate" />
                </Datagrid>
            </Card>
            <Pagination />
        </ListBase>
    );
};
