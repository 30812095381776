import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    NumberField,
    ReferenceField,
    TextField,
    ImageField
} from "react-admin";

import Divider from "@mui/material/Divider";

export const ExerciseShow: React.FC = () => (
    <Show>
        <SimpleShowLayout>
            <ImageField source="icon.src" title="icon.title" />
            <TextField source="name" />
            <NumberField source="stage" /> <TextField source="videoId" />
            <Divider sx={{width: "100%"}} />
            <TextField source="executionFrequency" />
            <Divider sx={{width: "100%"}} />
            <ReferenceField source="exerciseSetId" reference="exerciseSets" link="show">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
