import * as React from "react";
import {Edit} from "react-admin";

import {DiseaseForm} from "../forms";

export const DiseaseEdit: React.FC = () => (
    <Edit mutationMode="pessimistic">
        <DiseaseForm />
    </Edit>
);
