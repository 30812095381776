import React from "react";
import {Resource} from "react-admin";

import EngineeringIcon from "@mui/icons-material/Engineering";

import {AdminCreate} from "./views/admin.create";
import {AdminList} from "./views/admin.list";
import {AdminShow} from "./views/admin.show";

export function adminResource(): JSX.Element {
    return (
        <Resource
            icon={EngineeringIcon}
            name="admins"
            show={AdminShow}
            list={AdminList}
            create={AdminCreate}
        />
    );
}
