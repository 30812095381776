import * as React from "react";
import {Edit, DateTimeInput, SimpleForm, BooleanInput, TextInput, SelectInput} from "react-admin";

import {VisitIsRescheduledInput, VisitStaffEmailInput, VisitUserIdInput} from "../inputs";
import {
    validateNotAvailable,
    validateEnd,
    validateStart,
    validateTitle,
    validateStatus
} from "../visit.validation";

export const VisitEdit: React.FC = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <SelectInput
                source="status"
                choices={[
                    {id: "NEW", name: "NEW"},
                    {id: "CONFIRMED", name: "CONFIRMED"},
                    {id: "CANCELLED", name: "CANCELLED"}
                ]}
                defaultValue="CONFIRMED"
                validate={validateStatus}
            />
            <VisitIsRescheduledInput />
            <BooleanInput source="notAvailable" validate={validateNotAvailable} options={{}} />
            <TextInput source="title" validate={validateTitle} />
            <DateTimeInput source="start" validate={validateStart} />
            <DateTimeInput source="end" validate={validateEnd} />
            <VisitUserIdInput />
            <VisitStaffEmailInput />
        </SimpleForm>
    </Edit>
);
