import React from "react";
import {useFormContext} from "react-hook-form";

import {IHiddenInputProps} from "./HiddenInput.props";

export const HiddenInput: React.FC<IHiddenInputProps> = ({source, value}) => {
    const form = useFormContext();
    form.setValue(source, value);
    return null;
};
