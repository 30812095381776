import * as React from "react";
import {Create} from "react-admin";

import {UserForm} from "../forms";

export const UserCreate: React.FC = () => (
    <Create>
        <UserForm />
    </Create>
);
