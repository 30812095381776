import {IStaff} from "$types/staff.interface";

import React from "react";
import {useRecordContext, useTranslate, Labeled} from "react-admin";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {EWeekDays} from "../../../utils/calendar";

const dayOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
] as const;

type WorkDayHours = NonNullable<IStaff["workHours"]>[keyof typeof EWeekDays];
export const StaffWorkHoursField: React.FC = () => {
    const record = useRecordContext<IStaff>();
    const translate = useTranslate();
    const workHours = record?.workHours;

    if (!workHours) return null;

    return (
        <Labeled label={translate("resources.staff.fields.workHours")}>
            <Table sx={{width: "300px"}}>
                <TableBody>
                    {dayOrder
                        .map((dayName): [string, WorkDayHours] => [dayName, workHours[dayName]])
                        .filter((workDay): workDay is [string, NonNullable<WorkDayHours>] =>
                            Boolean(workDay[1]?.startTime && workDay[1].endTime)
                        )
                        .map(([dayName, workDayHours]) => (
                            <TableRow key={dayName}>
                                <TableCell>{translate(`weekday.${dayName}`)}</TableCell>
                                <TableCell>
                                    {workDayHours.startTime} - {workDayHours.endTime}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Labeled>
    );
};
