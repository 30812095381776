import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ReferenceArrayField,
    ReferenceField,
    ChipField,
    SingleFieldList
} from "react-admin";

import {ConcatenatedField} from "../../../custom-fields/concatenated-field";

export const UserRecommendationShow: React.FC = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceField reference="users" source="id" link="show">
                <ConcatenatedField sources={["firstname", "lastname"]} />
            </ReferenceField>
            <ReferenceArrayField reference="exerciseSets" source="exerciseSetIds">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField reference="diseases" source="diseaseIds">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);
