import * as React from "react";
import {Edit} from "react-admin";

import {ExerciseSetForm} from "../forms";

export const ExerciseSetEdit: React.FC = () => (
    <Edit>
        <ExerciseSetForm />
    </Edit>
);
