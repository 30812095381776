import englishMessagesPack from "ra-language-english";
import polishMessagesPack from "ra-language-polish";
import {TranslationMessages} from "react-admin";
import deepMerge from "ts-deepmerge";

import {ELocales} from "./locales.enum";
import englishDomainTranslations from "./translations/english.json";
import polishDomainTranslations from "./translations/polish.json";

const englishMessages: TranslationMessages = deepMerge(
    englishMessagesPack,
    englishDomainTranslations
) as TranslationMessages;

const polishMessages: TranslationMessages = deepMerge(
    polishMessagesPack,
    polishDomainTranslations
) as TranslationMessages;

export const messages: Record<ELocales, TranslationMessages> = {
    [ELocales.english]: englishMessages,
    [ELocales.polish]: polishMessages
};
