import React from "react";
import {Resource} from "react-admin";

import InsertChartIcon from "@mui/icons-material/InsertChart";

import {ProgressList} from "./views/progress.list";

export function progressResource(): JSX.Element {
    return <Resource icon={InsertChartIcon} name="progress" list={ProgressList} />;
}
