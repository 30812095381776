import React from "react";
import {
    FieldTitle,
    InputHelperText,
    InputProps,
    sanitizeInputRestProps,
    useInput
} from "react-admin";

import TextField, {TextFieldProps} from "@mui/material/TextField";

export type TimeInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, "helperText" | "label">;

const defaultInputLabelProps = {shrink: true} as const;

const defaultFormatParseFunction = <T,>(val: T): T => val;

export const TimeInput: React.FC<TimeInputProps> = ({
    defaultValue,
    format = defaultFormatParseFunction,
    label,
    helperText,
    margin = "dense",
    onBlur,
    onChange,
    source,
    resource,
    parse = defaultFormatParseFunction,
    validate,
    variant = "filled",
    ...rest
}) => {
    const {id, field, fieldState, isRequired} = useInput({
        defaultValue,
        format,
        onBlur,
        onChange,
        parse,
        resource,
        source,
        type: "time",
        validate,
        ...rest
    });
    const {error, invalid, isTouched = false} = fieldState;
    return (
        <TextField
            id={id}
            {...field}
            value={format(field.value) || ""}
            variant={variant || "filled"}
            margin={margin || "dense"}
            error={!!(isTouched && (error || invalid))}
            helperText={
                <InputHelperText
                    touched={isTouched}
                    error={error?.message}
                    helperText={helperText}
                />
            }
            label={
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            }
            InputLabelProps={defaultInputLabelProps}
            {...sanitizeInputRestProps(rest)}
        />
    );
};
