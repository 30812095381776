import {IExerciseSet} from "$types/exercise-set.interface";

import React from "react";
import {
    AutocompleteInput,
    Identifier,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    SimpleFormProps,
    TextInput
} from "react-admin";

import Divider from "@mui/material/Divider";

import {ExerciseSetRecordRepresentation} from "../../exercise-set";
import {
    validateDescription,
    validateName,
    validateExecutionFrequency,
    validateVideoId,
    validateStage,
    validateExerciseSetId
} from "../exercise.validation";

export const ExerciseForm: React.FC<
    Omit<SimpleFormProps, "children"> & {exerciseSetId?: Identifier}
> = ({exerciseSetId, ...props}) => {
    return (
        <SimpleForm {...props}>
            <TextInput source="name" validate={validateName} fullWidth />
            <NumberInput source="stage" validate={validateStage} step={1} />
            <TextInput
                source="description"
                validate={validateDescription}
                multiline={true}
                fullWidth
            />
            <TextInput source="videoId" validate={validateVideoId} fullWidth />
            <ImageInput source="icon" accept="image/*" maxSize={2097152 /*2MB size*/}>
                <ImageField source="src" title="image" />
            </ImageInput>

            <Divider sx={{width: "100%"}} />

            <TextInput
                source="executionFrequency"
                validate={validateExecutionFrequency}
                fullWidth
            />

            <Divider sx={{width: "100%"}} />

            <ReferenceInput source="exerciseSetId" reference="exerciseSets">
                <AutocompleteInput
                    fullWidth
                    filterToQuery={(q: string): Record<string, string> => ({name: q})}
                    optionText={<ExerciseSetRecordRepresentation />}
                    inputText={(option: IExerciseSet): string => option.name}
                    matchSuggestion={(q: string, option: IExerciseSet): boolean =>
                        option.name.toLowerCase().includes(q.toLowerCase().trim())
                    }
                    clearOnBlur={false}
                    disabled={Boolean(exerciseSetId)}
                    defaultValue={exerciseSetId}
                    validate={validateExerciseSetId}
                />
            </ReferenceInput>
        </SimpleForm>
    );
};
