import React from "react";
import {Resource} from "react-admin";

import RecommendIcon from "@mui/icons-material/Recommend";

import {UserRecommendationEdit} from "./views/user-recommendation.edit";
import {UserRecommendationShow} from "./views/user-recommendation.show";

export function userRecommendationResource(): JSX.Element {
    return (
        <Resource
            icon={RecommendIcon}
            name="userRecommendations"
            show={UserRecommendationShow}
            edit={UserRecommendationEdit}
        />
    );
}
