import * as React from "react";
import {Show, SimpleShowLayout, EmailField} from "react-admin";

export const AdminShow: React.FC = () => (
    <Show>
        <SimpleShowLayout>
            <EmailField source="id" />
        </SimpleShowLayout>
    </Show>
);
