import React from "react";
import {Resource} from "react-admin";

import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

import {ExerciseCreate} from "./views/exercise.create";
import {ExerciseEdit} from "./views/exercise.edit";
import {ExerciseList} from "./views/exercise.list";
import {ExerciseShow} from "./views/exercise.show";

export function exerciseResource({isAdmin}: {isAdmin?: boolean}): JSX.Element {
    return (
        <Resource
            icon={FitnessCenterIcon}
            name="exercises"
            show={ExerciseShow}
            list={ExerciseList}
            create={isAdmin ? ExerciseCreate : void 0}
            edit={isAdmin ? ExerciseEdit : void 0}
        />
    );
}
