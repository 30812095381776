import {query, where} from "firebase/firestore";
import * as React from "react";
import {
    List,
    Datagrid,
    ShowButton,
    EditButton,
    DeleteButton,
    EmailField,
    TextField,
    usePermissions,
    TextInput
} from "react-admin";
import {
    FireStoreCollectionRef,
    FireStoreQuery
} from "react-admin-firebase/dist/misc/firebase-models";

export const UserList: React.FC = () => {
    const {permissions} = usePermissions();

    if (!permissions) return null;

    const collectionQuery = (ref: FireStoreCollectionRef): FireStoreQuery => {
        // admin can see all users
        if (permissions.isAdmin) return ref;
        // staff can only see their own patients
        return query(ref, where("staff", "array-contains", permissions.email));
    };

    return (
        <List
            filter={{collectionQuery}}
            filters={[
                <TextInput source="email" key="email" alwaysOn />,
                <TextInput source="firstname" key="firstname" alwaysOn />,
                <TextInput source="lastname" key="lastname" alwaysOn />
            ]}
        >
            <Datagrid>
                <EmailField source="email" />
                <TextField source="firstname" />
                <TextField source="lastname" />
                <ShowButton label="" />
                {permissions.isAdmin && <EditButton label="" />}
                {permissions.isAdmin && <DeleteButton label="" />}
            </Datagrid>
        </List>
    );
};
