import {IStaff} from "$types/staff.interface";

import React from "react";
import {
    ArrayInput,
    DateInput,
    FileField,
    FileInput,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate
} from "react-admin";

import {formatPhoneNumber} from "../../../utils/phone-numbers";
import {
    GENDER_ENUM,
    validateBirthDate,
    validateBoughtVisitsCount,
    validateDescription,
    validateEmail,
    validateFirstName,
    validateGender,
    validateHeight,
    validateLastName,
    validatePhone,
    validateWeight
} from "../user.validation";

export const UserForm: React.FC = () => {
    const translate = useTranslate();
    return (
        <SimpleForm>
            <TextInput source="firstname" validate={validateFirstName} fullWidth />
            <TextInput source="lastname" validate={validateLastName} fullWidth />
            <TextInput
                source="description"
                validate={validateDescription}
                fullWidth
                multiline
                minRows={5}
            />
            <TextInput source="email" type="email" validate={validateEmail} fullWidth />
            <TextInput source="registrationToken" type="registrationToken" disabled />
            <NumberInput source="height" validate={validateHeight} fullWidth />
            <NumberInput source="weight" validate={validateWeight} fullWidth />
            <DateInput source="birthdate" validate={validateBirthDate} fullWidth />
            <TextInput
                source="phone"
                format={formatPhoneNumber}
                validate={validatePhone}
                fullWidth
            />
            <ImageInput source="userAvatar" accept="image/*" maxSize={2097152 /*2MB size*/}>
                <ImageField source="src" title="image" />
            </ImageInput>
            <SelectInput
                source="gender"
                choices={Object.entries(GENDER_ENUM).map(([key, val]) => ({
                    id: val,
                    name: translate(`gender.${key}`)
                }))}
                validate={validateGender}
                defaultValue={GENDER_ENUM.UNSPECIFIED}
                fullWidth
            />
            <ReferenceArrayInput reference="staff" source="staff" fullWidth>
                <SelectArrayInput
                    defaultValue={[]}
                    label={translate("resources.staff.name", {smart_count: 2})}
                    optionText={(data: IStaff): string => `${data.firstname} ${data.lastname}`}
                    fullWidth
                />
            </ReferenceArrayInput>

            <ReferenceArrayInput reference="diseases" source="diseases" fullWidth>
                <SelectArrayInput
                    defaultValue={[]}
                    label={translate("resources.diseases.name", {smart_count: 2})}
                    optionText="name"
                    fullWidth
                />
            </ReferenceArrayInput>

            <ArrayInput source="files" fullWidth>
                <SimpleFormIterator fullWidth>
                    <FileInput source="fileData" accept="application/pdf">
                        <FileField source="src" title="file" />
                    </FileInput>
                </SimpleFormIterator>
            </ArrayInput>
            <NumberInput
                source="boughtVisitsCount"
                validate={validateBoughtVisitsCount}
                defaultValue={0}
                step={1}
                min={0}
            />
        </SimpleForm>
    );
};
