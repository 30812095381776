import React from "react";
import {ImageField, ImageInput, NumberInput, SimpleForm, TextInput} from "react-admin";

import {
    validateDescription,
    validateName,
    validateNewPackagesPrice,
    validateNewVisitsPrice,
    validateRevenueCatOfferIdentifier
} from "../disease.validation";

export const DiseaseForm: React.FC = () => (
    <SimpleForm>
        <ImageInput source="icon" accept="image/*" maxSize={2097152 /*2MB size*/}>
            <ImageField source="src" title="image" />
        </ImageInput>
        <TextInput source="name" validate={validateName} />
        <TextInput source="description" validate={validateDescription} fullWidth multiline />
        <TextInput
            source="revenueCatOfferIdentifier"
            validate={validateRevenueCatOfferIdentifier}
        />
        <NumberInput
            source="discounts.newPackagesPrice"
            validate={validateNewPackagesPrice}
            step={0.01}
        />
        <NumberInput
            source="discounts.newVisitsPrice"
            validate={validateNewVisitsPrice}
            step={0.01}
        />
    </SimpleForm>
);
