import {IExerciseSet} from "$types/exercise-set.interface";

import React from "react";
import {Resource, useGetOne, useRecordContext} from "react-admin";

import TimerIcon from "@mui/icons-material/Timer";

import {ExerciseSetCreate} from "./views/exercise-set.create";
import {ExerciseSetEdit} from "./views/exercise-set.edit";
import {ExerciseSetList} from "./views/exercise-set.list";
import {ExerciseSetShow} from "./views/exercise-set.show";

export const ExerciseSetRecordRepresentation: React.FC = () => {
    const record = useRecordContext<IExerciseSet>();
    const {data: disease} = useGetOne("diseases", {id: record.diseaseId});

    if (!disease?.name) return <span>{record.name}</span>;
    return (
        <span>
            {record.name} ({disease.name})
        </span>
    );
};

export function exerciseSetResource({isAdmin}: {isAdmin?: boolean}): JSX.Element {
    return (
        <Resource
            icon={TimerIcon}
            name="exerciseSets"
            show={ExerciseSetShow}
            list={ExerciseSetList}
            create={isAdmin ? ExerciseSetCreate : void 0}
            edit={isAdmin ? ExerciseSetEdit : void 0}
            recordRepresentation={<ExerciseSetRecordRepresentation />}
        />
    );
}
