import {IUserRecommendation} from "$types/user-recommendation.interface";

import {Button} from "ra-ui-materialui";
import * as React from "react";
import {useState} from "react";
import {
    Show,
    TextField,
    SimpleShowLayout,
    EmailField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    useTranslate,
    Pagination,
    ShowButton,
    ReferenceArrayField,
    ArrayField,
    FileField,
    SingleFieldList,
    TopToolbar,
    EditButton,
    DeleteButton,
    usePermissions,
    useGetOne,
    useGetRecordId,
    ImageField
} from "react-admin";
import {Link} from "react-router-dom";

import ContentAdd from "@mui/icons-material/Add";
import ContentShow from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import {ConcatenatedField} from "../../../custom-fields/concatenated-field";
import {UserRecommendationQuickCreate} from "../../user-recommendation/views/user-recommendation.quick-create";

const UserShowActions: React.FC = () => {
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const {permissions} = usePermissions();
    const translate = useTranslate();
    const userId = useGetRecordId();

    const {data: userRecommendations, isLoading} = useGetOne<IUserRecommendation>(
        "userRecommendations",
        {id: String(userId)},
        {retry: false}
    );
    // there is a bug in react admin firebase dataProvider that returns an object with an id even if it does not exist
    const userHasRecommendations =
        userRecommendations && Object.keys(userRecommendations).length > 1;

    if (!userId || !permissions || isLoading) return null;

    return (
        <TopToolbar>
            {userHasRecommendations ? (
                <EditButton
                    resource="userRecommendations"
                    label="resources.userRecommendations.name"
                />
            ) : (
                <>
                    <Button
                        onClick={(): void => setShowCreateDialog(true)}
                        label={translate("quick-create.userRecommendation")}
                    >
                        <ContentAdd />
                    </Button>
                    <Dialog
                        fullWidth
                        open={showCreateDialog}
                        onClose={(): void => setShowCreateDialog(false)}
                        aria-label={translate("quick-create.userRecommendation")}
                    >
                        <DialogTitle>{translate("quick-create.userRecommendation")}</DialogTitle>
                        <UserRecommendationQuickCreate userId={userId} />
                    </Dialog>
                </>
            )}
            <Button
                label={translate("resources.progressStats.name")}
                component={Link}
                to={{
                    pathname: "/progressStats",
                    search: `filter=${JSON.stringify({userId})}`
                }}
            >
                <ContentShow />
            </Button>
            <Button
                label={translate("resources.progress.name", {smart_count: 2})}
                component={Link}
                to={{
                    pathname: "/progress",
                    search: `filter=${JSON.stringify({userId})}`
                }}
            >
                <ContentShow />
            </Button>
            {permissions.isAdmin && <EditButton />}
            {permissions.isAdmin && <DeleteButton />}
        </TopToolbar>
    );
};

export const UserShow: React.FC = () => {
    const translate = useTranslate();
    return (
        <Show actions={<UserShowActions />}>
            <SimpleShowLayout>
                <TextField source="firstname" />
                <TextField source="lastname" />
                <EmailField source="email" />
                <NumberField source="height" />
                <NumberField source="weight" />
                <TextField source="description" />
                <DateField source="birthdate" />
                <ArrayField source="files">
                    <Datagrid>
                        <FileField source="useFile.src" title="file" />
                    </Datagrid>
                </ArrayField>

                <ImageField source="userAvatar.src" title="userAvatar.title" />
                <ReferenceArrayField
                    reference="staff"
                    source="staff"
                    label={translate("resources.staff.name", {smart_count: 2})}
                >
                    <SingleFieldList>
                        <ConcatenatedField sources={["firstname", "lastname"]} />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ReferenceManyField
                    reference="visits"
                    target="userId"
                    label={translate("resources.visits.name", {smart_count: 2})} // plural
                    sort={{field: "start", order: "DESC"}}
                    perPage={10}
                    pagination={<Pagination />}
                    fullWidth={true}
                >
                    <Datagrid bulkActionButtons={false}>
                        <DateField source="start" showTime={true} />
                        <DateField source="end" showTime={true} />
                        <TextField source="staffEmail" />
                        <ShowButton label="" />
                    </Datagrid>
                </ReferenceManyField>
                <NumberField source="boughtVisitsCount" />
            </SimpleShowLayout>
        </Show>
    );
};
