import React from "react";
import {EditButton, usePermissions, useRecordContext} from "react-admin";

export const VisitCommentEditButton: React.FC = () => {
    const {permissions} = usePermissions();
    const record = useRecordContext();

    if (!permissions || (!permissions.isAdmin && record.createdby !== permissions.email))
        return null;
    return <EditButton label="" />;
};
