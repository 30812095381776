import * as React from "react";
import {Create} from "react-admin";

import {StaffForm} from "../forms";

export const StaffCreate: React.FC = () => {
    return (
        <Create>
            <StaffForm />
        </Create>
    );
};
