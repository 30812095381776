import React, {useEffect} from "react";
import {ReferenceInput, SelectInput, usePermissions} from "react-admin";
import {useFormContext} from "react-hook-form";

import {validateStaffEmail} from "../visit.validation";

export const VisitStaffEmailInput: React.FC = () => {
    const {setValue} = useFormContext();
    const {permissions} = usePermissions();

    useEffect(() => {
        if (!permissions) return;
        const {isAdmin, email} = permissions;
        if (!isAdmin) setValue("staffEmail", email);
    }, [permissions, setValue]);

    if (!permissions?.isAdmin) return null;

    return (
        <ReferenceInput
            source="staffEmail"
            reference="staff"
            validate={validateStaffEmail}
            required={true}
        >
            <SelectInput optionText="id" />
        </ReferenceInput>
    );
};
