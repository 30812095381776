import {maxLength, minLength, minValue, required, Validator} from "react-admin";

import {integer} from "../../validators";

export const validateName: Array<Validator> = [required(), minLength(2), maxLength(128)];
export const validateDescription: Array<Validator> = [maxLength(512)];
export const validateRevenueCatIdentifier: Array<Validator> = [];
export const validateDiseaseId: Array<Validator> = [required()];
export const validateStage: Array<Validator> = [required(), minValue(1), integer()];
export const validateExerciseCount: Array<Validator> = [required(), minValue(0), integer()];
